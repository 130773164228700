import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const HTMLAccordionRow = ({ html }) => {
  return (
    <Container>
      <Body dangerouslySetInnerHTML={{ __html: html }} />
    </Container>
  )
}

HTMLAccordionRow.propTypes = {
  html: PropTypes.string.isRequired,
}

const Container = styled.div`
  background-color: #fafafa;
  border-bottom: 1px solid #d7d7d7;
  height: auto;
  padding: 15px 25px;
`

const Body = styled.div`
  a {
    color: #377539;
    text-decoration: underline;
  }
`

export default HTMLAccordionRow
