import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const Feature = ({ content }) => {
  return (
    <StyledFeature
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  )
}

Feature.propTypes = {
  content: PropTypes.string.isRequired,
}

const StyledFeature = styled.div`
  background-color: #fafafa;
  border-bottom: 1px solid #d7d7d7;
  height: auto;
  font-size: 16px;
  padding: 15px 25px;

  h3,
  h4,
  h5,
  h6,
  h {
    margin: 24px 0 6px;
    max-width: none;
    text-align: left;
    text-transform: none;
    width: 100%;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 21px;
  }

  h6 {
    font-size: 18px;
  }

  img {
    display: block;
    margin-top: 20px;
    max-width: 100%;
    width: auto;
  }

  .caption {
    display: block;
    font-style: italic;
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    margin: 10px 0 20px;
  }

  p {
    font-size: 16px;
  }

  table {
    border: 1px solid #d7d7d7;
    border-collapse: collapse;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;

    tr {
      :not(:last-of-type) {
        border-bottom: 1px solid #d7d7d7;
      }
    }

    td {
      padding: 4px;
      text-align: center;

      :not(:last-of-type) {
        border-right: 1px solid #d7d7d7;
      }
    }
  }
`

export default Feature
