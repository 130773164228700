import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/button/button'
import AccordionRow from './accordion-row'
import { AccordionTypes } from './types'
import UnstyledButton from 'components/button/unstyled-button'
import { CollapseStyle, COLLAPSE_DURATION } from 'components/collapse'

const Accordion = ({ data, type, header }) => {
  const [open, setOpen] = useState(data.map(() => false))
  const [showing, setShowing] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)

  const toggleExpand = () => {
    if (isAnimating) {
      return
    }
    setIsAnimating(true)
    setOpen(open.map(() => !expanded))
    setExpanded(!expanded)

    setTimeout(() => {
      setShowing(!expanded)
      setIsAnimating(false)
    }, COLLAPSE_DURATION)
  }

  const toggleOne = index => {
    if (isAnimating) {
      return
    }
    const updated = open.map((e, i) => {
      if (i === index) {
        return !e
      }
      return e
    })
    setOpen(updated)
    setExpanded(updated.every(val => val))
  }
  return (
    <>
      <CollapseStyle />
      <Header>
        <Title>{header || type}</Title>
        <ChangeView>
          <ChangeViewOption
            className='change-view-option'
            aria-expanded={expanded}
            onClick={toggleExpand}
          >
            <span>{expanded ? 'Collapse All' : 'Expand All'}</span>
          </ChangeViewOption>
        </ChangeView>
      </Header>
      <AccordionContent showing={showing}>
        {data.map((e, key) => (
          <AccordionRow
            className='row'
            data={e}
            open={open[key]}
            toggle={toggleOne}
            index={key}
            type={type}
            key={key}
          />
        ))}
      </AccordionContent>
      {data.length > 5 && (
        <ExpandButton color='green' ghost onClick={() => setShowing(!showing)}>
          {showing ? 'Show Less' : 'Show More'}
        </ExpandButton>
      )}
    </>
  )
}

Accordion.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.string,
  type: AccordionTypes.isRequired,
}

const Header = styled.div`
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 30px;
`

const Title = styled.h2`
  display: inline-block;
  text-transform: capitalize;
  vertical-align: middle;
  width: 50%;
`

const ChangeView = styled.div`
  display: inline-block;
  text-align: right;
  width: 50%;
`

const ChangeViewOption = styled(UnstyledButton)`
  border-bottom: none;
  cursor: pointer;
  display: inline-block;
  padding: 10px;

  span {
    color: #377539;
    font-weight: 600;
    vertical-align: middle;
  }

  :hover,
  :focus {
    text-decoration: underline;
  }
`

const AccordionContent = styled.div`
  .row:nth-child(n + 6) {
    display: ${props => (props.showing ? 'block' : 'none')};
  }
`

const ExpandButton = styled(Button)`
  margin: 30px 0 0;
  max-width: 250px;
`

export default Accordion
