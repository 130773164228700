import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Button from 'components/button'

const Offer = ({ description, disclaimer, link, subTitle, title }) => {
  return (
    <OfferContainer>
      <Title dangerouslySetInnerHTML={{ __html: `${title} ${subTitle ? subTitle : ''}` }} />
      <Description
        className='description'
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      {disclaimer && <Disclaimer dangerouslySetInnerHTML={{ __html: disclaimer }} />}
      {link && (
        <ButtonContainer>
          <Link to={link}>
            <Button>View offer</Button>
          </Link>
        </ButtonContainer>
      )}
    </OfferContainer>
  )
}

Offer.propTypes = {
  description: PropTypes.string.isRequired,
  disclaimer: PropTypes.string,
  link: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
}

const OfferContainer = styled.div`
  background-color: #fafafa;
  border-bottom: 1px solid #d7d7d7;
  height: auto;
  padding: 15px 25px;
`

const Title = styled.h3`
  font-size: 1.25em;
  font-weight: bold;
  margin: 20px 0;
  max-width: none;
  text-transform: none;
  width: 100%;
`

const Description = styled.div`
  margin-bottom: 25px;

  h3,
  h4 {
    font-size: 1.05em;
    max-width: none;
    margin: 10px 0;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-align: left;

    u {
      display: inline-block;
      margin: 5px 0 0;
    }
  }

  a {
    color: #377539;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }

  #disclaimer {
    font-size: 0.7em;
  }
`

const Disclaimer = styled.div`
  font-size: 0.7em;
`

const ButtonContainer = styled.div`
  width: 200px;
`

export default Offer
