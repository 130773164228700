import React from 'react'
import * as PropTypes from 'prop-types'
import { FaCaretRight } from '@react-icons/all-files/fa/FaCaretRight'
import { FaCaretUp } from '@react-icons/all-files/fa/FaCaretUp'
import { FaCaretDown } from '@react-icons/all-files/fa/FaCaretDown'

const components = {
  up: FaCaretUp,
  down: FaCaretDown,
  right: FaCaretRight,
}

const ToggleArrow = ({ open, activeDirection, inactiveDirection, ...props }) => {
  const ActiveArrow = components[activeDirection]
  const InactiveArrow = components[inactiveDirection]
  return (
    <>
      {open && <ActiveArrow aria-hidden='true' focusable='false' role='presentation' {...props} />}
      {!open && (
        <InactiveArrow aria-hidden='true' focusable='false' role='presentation' {...props} />
      )}
    </>
  )
}

ToggleArrow.propTypes = {
  activeDirection: PropTypes.oneOf(['up', 'down', 'right']),
  inactiveDirection: PropTypes.oneOf(['up', 'down', 'right']),
  open: PropTypes.bool.isRequired,
}

ToggleArrow.defaultProps = {
  activeDirection: 'down',
  inactiveDirection: 'right',
}

export default ToggleArrow
