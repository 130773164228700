import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const Spec = ({ specs }) => {
  return (
    <SpecTable>
      <tbody>
        {specs.map((spec, key) => (
          <tr key={key}>
            <td
              dangerouslySetInnerHTML={{
                __html: spec.name,
              }}
            />
            <td
              dangerouslySetInnerHTML={{
                __html: spec.description,
              }}
            />
          </tr>
        ))}
      </tbody>
    </SpecTable>
  )
}

Spec.propTypes = {
  specs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
}

const SpecTable = styled.table`
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  tr {
    border-bottom: 1px solid #d7d7d7;
    display: block;
    padding: 15px;

    :nth-child(even) {
      background-color: #f7f7f7;
    }
  }

  td {
    padding: 0;
    display: block;

    :first-child {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  @media ${props => props.theme.mediaQuery.mediumUp} {
    tr {
      display: table-row;
      padding: auto;
    }

    td {
      display: table-cell;
      margin: auto;
      padding: 15px;

      :first-child {
        border-right: 1px solid #d7d7d7;
        width: 200px;
      }
    }
  }
`

export default Spec
