import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { AccordionTypes } from './types'
import { Collapse } from 'components/collapse'
import ToggleArrow from 'components/toggle-arrow'
import FeatureRow from './accordion-rows/feature'
import SpecRow from './accordion-rows/specification'
import OfferRow from './accordion-rows/offer'
import FAQRow from './accordion-rows/faq'
import HTMLRow from './accordion-rows/html'
import UnstyledButton from 'components/button/unstyled-button'

const AccordionRow = ({ toggle, index, data, type, open, ...props }) => {
  const onToggle = () => {
    toggle(index)
  }
  return (
    <Container {...props}>
      <Header open={open} onClick={onToggle}>
        <StyledToggleArrow open={open} />
        <Title
          dangerouslySetInnerHTML={{
            __html: getTitle(data, type),
          }}
          type={type}
        />
      </Header>
      <Collapse isOpened={open}>{getRow(data, type)}</Collapse>
    </Container>
  )
}

AccordionRow.propTypes = {
  data: PropTypes.object.isRequired,
  type: AccordionTypes.isRequired,
  index: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

const getRow = (data, type) => {
  switch (type) {
    case 'features':
      return <FeatureRow content={data.content} />
    case 'specs':
      return <SpecRow specs={data.specs} />
    case 'offers':
      return (
        <OfferRow
          title={data.title}
          subTitle={data.subTitle}
          description={data.description || data.html}
          link={data.link || data.slug}
          disclaimer={data.disclaimer}
        />
      )
    case 'faq':
      return <FAQRow answer={data.answer} />
    case 'html':
      return <HTMLRow html={data.html} />
    default:
      return
  }
}

const getTitle = (data, type) => {
  switch (type) {
    case 'html':
    case 'features':
    case 'offers':
      return data.title
    case 'specs':
      return data.name
    case 'faq':
      return data.question
    default:
      return ''
  }
}

const Container = styled.div`
  box-sizing: border-box;
`

const Header = styled(UnstyledButton)`
  background-color: ${props => (props.open ? '#000' : '#fff')};
  border-bottom: 1px solid #d7d7d7;
  color: ${props => (props.open ? '#fff' : '#000')};
  text-align: left;
  width: 100%;

  :hover,
  :focus {
    background-color: ${props => (props.open ? '#000' : '#f7f7f7')};
    cursor: pointer;
  }
`

const StyledToggleArrow = styled(ToggleArrow)`
  margin-left: 10px;
  vertical-align: middle;
`

const Title = styled.h3`
  display: inline-block;
  font-size: ${props => (props.type === 'faq' ? '1.2em' : '1.35em')};
  font-weight: ${props => (props.type === 'faq' ? 600 : 'bold')};
  margin: 0;
  max-width: 90%;
  padding: ${props => (props.type === 'faq' ? '12px 10px' : '10px')};
  text-transform: none;
  vertical-align: middle;
`

export default AccordionRow
