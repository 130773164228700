import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const FAQ = ({ answer }) => {
  return (
    <FAQContainer>
      <Answer>{answer}</Answer>
    </FAQContainer>
  )
}

FAQ.propTypes = {
  answer: PropTypes.string.isRequired,
}

const FAQContainer = styled.div`
  background-color: #fafafa;
  border-bottom: 1px solid #d7d7d7;
  height: auto;
  padding: 15px 25px;
`

const Answer = styled.p`
  margin: 0;
  padding: 0;
`

export default FAQ
