import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useToggle } from 'hooks/use-toggle'
import scrollTo from 'utils/scroll-to'
import H2L from 'components/typography/h2'
import { Helmet } from 'react-helmet'
import Accordion from 'components/accordion'
import SimilarProducts from 'components/similar-products'
import {
  // organizationSchema,
  StihlBrand,
  StihlOrganization,
} from 'data/schema-data'
import Layout from 'components/layout'
import Content from 'components/content'
import { graphql } from 'gatsby'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'
import TopFold from 'components/products/top-fold'
import QuoteForm from 'components/forms/quote'
import Modal from 'components/modal/modal'
import { getSrc } from 'gatsby-plugin-image'

const StihlProductTemplate = props => {
  const {
    data: { sanityStihlEquipment, allHutsonPromotion },
  } = props

  const [
    {
      breadcrumbs,
      features,
      images,
      meta,
      offers,
      pricingLinks,
      productSchema,
      similarProducts,
      // variations,
    },
  ] = useState(() => {
    const breadcrumbs = [
      {
        name: 'Home',
        schemaName: 'Hutson Inc',
        link: '/',
      },
      {
        name: 'STIHL',
        link: '/stihl/',
      },
      {
        name: sanityStihlEquipment.category.title,
        link: `/stihl/${sanityStihlEquipment.category.handle}/`,
      },
      {
        name: sanityStihlEquipment.subcategory.title,
        link: `/stihl/${sanityStihlEquipment.category.handle}/${sanityStihlEquipment.subcategory.handle}/`,
      },
      {
        name: sanityStihlEquipment.title,
        link: sanityStihlEquipment.slug,
      },
    ]

    const features =
      Array.isArray(sanityStihlEquipment.features) && sanityStihlEquipment.length > 0
        ? sanityStihlEquipment.features.map(feature => {
            return {
              title: feature.title,
              content: feature.descriptionHtml,
            }
          })
        : []

    const pricingLinks = []
    const offers = []
    if (Array.isArray(allHutsonPromotion.nodes) && allHutsonPromotion.nodes.length > 0) {
      allHutsonPromotion.nodes.forEach(promotion => {
        offers.push({
          title: promotion.title,
          html: promotion.html,
          link: promotion.link || promotion.slug,
        })
      })
      pricingLinks.push(
        <a href='#offers' onClick={scrollTo} key='current-promotions'>
          View current promotions
        </a>
      )
    }

    const similarProducts = Array.isArray(sanityStihlEquipment.similarEquipment)
      ? sanityStihlEquipment.similarEquipment.slice(0, 3).map(obj => {
          return {
            id: obj._id,
            image: getSrc(obj.firstImage.asset.gatsbyImageData),
            imageAlt: obj.title,
            link: obj.slug,
            title: obj.title,
          }
        })
      : []

    const meta = {
      title: `STIHL ${sanityStihlEquipment.title} | Hutson Inc`,
      description:
        sanityStihlEquipment.description ||
        `Learn more about the STIHL ${sanityStihlEquipment.title} at Hutson.`,
      pageUrl: `https://www.hutsoninc.com${sanityStihlEquipment.slug}`,
      images: [],
    }

    const images = sanityStihlEquipment.images.map((image, index) => {
      const original = getSrc(image.asset.original)
      const thumbnail = getSrc(image.asset.thumbnail)

      meta.images.push(original)

      return {
        original,
        thumbnail,
        originalAlt: sanityStihlEquipment.title,
        thumbnailAlt: `Photo ${index + 1} of ${sanityStihlEquipment.images.length}`,
      }
    })

    const productSchema = {
      '@context': 'http://schema.org',
      '@type': 'Product',
      'description': meta.description,
      'name': sanityStihlEquipment.title,
      'url': meta.pageUrl,
      'image': meta.images[0],
      'sku': sanityStihlEquipment.sku,
      'category': `${sanityStihlEquipment.category.title}/${sanityStihlEquipment.subcategory.title}`,
      'brand': StihlBrand,
      'manufacturer': StihlOrganization,
      // ...(Array.isArray(sanityStihlEquipment.options) && sanityStihlEquipment.options.length > 0
      //   ? {
      //       offers: {
      //         priceCurrency: 'USD',
      //         priceValidUntil: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000).toISOString(), // Today + 90 days
      //         itemCondition: 'https://schema.org/NewCondition',
      //         seller: organizationSchema,
      //         url: meta.pageUrl,
      //         ...(sanityStihlEquipment.options.length === 1
      //           ? {
      //               '@type': 'Offer',
      //               'price': sanityStihlEquipment.options[0].price,
      //             }
      //           : {
      //               '@type': 'AggregateOffer',
      //               'lowPrice': sanityStihlEquipment.options.reduce((min, option) => {
      //                 if (!min || option.price < min) {
      //                   return option.price
      //                 }
      //                 return min
      //               }, null),
      //               'highPrice': sanityStihlEquipment.options.reduce((max, option) => {
      //                 if (!max || option.price > max) {
      //                   return option.price
      //                 }
      //                 return max
      //               }, null),
      //               'offerCount': sanityStihlEquipment.options.length,
      //             }),
      //       },
      //     }
      //   : {}),
    }

    // const variations = sanityStihlEquipment.options.map(option => {
    //   return {
    //     text: option.title,
    //     value: option.price,
    //   }
    // })

    return {
      breadcrumbs,
      features,
      images,
      meta,
      offers,
      pricingLinks,
      productSchema: JSON.stringify(productSchema),
      similarProducts,
      // variations,
    }
  })
  const [open, toggleModal] = useToggle(false)

  useEffect(() => {
    if (window && window.dataLayer && Array.isArray(sanityStihlEquipment.options)) {
      window.dataLayer.push({
        'product-price': sanityStihlEquipment.options[0].value,
      })
    }
  }, [sanityStihlEquipment.options])
  return (
    <Layout kind='white'>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        <meta property='og:title' content={meta.title} />
        <meta property='og:description' content={meta.description} />
        <meta property='og:url' content={meta.pageUrl} />
        {meta.images.map((img, key) => (
          <meta property='og:image' content={img} key={key} />
        ))}
        <meta property='twitter:card' content='summary' />
        <meta name='twitter:site' content='@Hutson_Inc' />
        <script type='application/ld+json'>{productSchema}</script>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Content>
        <TopFold
          title={`STIHL ${sanityStihlEquipment.title}`}
          // variations={variations}
          description={sanityStihlEquipment.description}
          images={images}
          toggleModal={toggleModal}
          pricingLinks={pricingLinks}
          breadcrumbs={breadcrumbs}
          buttonText='Talk to sales'
          stihlBOPUSUrl={sanityStihlEquipment.buyOnlineSlug}
          priceDisclaimer=''
          buttonDisclaimer="This product must be picked up in-store per STIHL's sales policies"
        />

        {features && features.length > 0 && (
          <Section>
            <Accordion
              data={features}
              type='features'
              header={`STIHL ${sanityStihlEquipment.title} Features`}
            />
          </Section>
        )}

        {Array.isArray(sanityStihlEquipment.specifications) &&
        sanityStihlEquipment.specifications.length > 0 ? (
          <Section>
            <SpecsTable>
              <thead>
                <tr>
                  <td colSpan='2'>STIHL {sanityStihlEquipment.title} Specifications</td>
                </tr>
              </thead>
              <tbody>
                {sanityStihlEquipment.specifications.map(spec => (
                  <tr key={spec.title}>
                    <td>{spec.title}</td>
                    <td>{spec.description}</td>
                  </tr>
                ))}
              </tbody>
            </SpecsTable>
          </Section>
        ) : null}

        {Array.isArray(offers) && offers.length > 0 ? (
          <Section id='offers'>
            <Accordion
              data={offers}
              type='offers'
              header={`STIHL ${sanityStihlEquipment.title} Offers & Discounts`}
            />
          </Section>
        ) : null}

        {Array.isArray(similarProducts) && similarProducts.length > 0 ? (
          <Section>
            <H2L>Similar Products</H2L>
            <SimilarProducts similarProducts={similarProducts} />
          </Section>
        ) : null}
      </Content>

      {!sanityStihlEquipment.buyOnlineSlug && (
        <Modal toggle={toggleModal} isOpen={open}>
          <QuoteForm
            toggleModal={toggleModal}
            productName={sanityStihlEquipment.title}
            productPrice={
              Array.isArray(sanityStihlEquipment.options) && sanityStihlEquipment.options.length > 0
                ? sanityStihlEquipment.options[0].price
                : 0
            }
            productType='stihl'
            submitButtonClass='submit-aftermarket-quote'
            pageURL={meta.pageUrl}
            equipmentBrand='STIHL'
            equipmentCondition='new'
          />
        </Modal>
      )}
    </Layout>
  )
}

const Section = styled.div`
  margin-top: 40px;

  @media (min-width: 800px) {
    margin-top: 80px;
  }
`

const SpecsTable = styled.table`
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  thead {
    color: #fff;
    background-color: #000;
    font-size: 1.35em;
    font-weight: bold;
    margin: 0;
    text-transform: none;
    vertical-align: middle;

    td {
      padding: 15px;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #d7d7d7;
      display: block;
      padding: 15px;

      :nth-child(even) {
        background-color: #f7f7f7;
      }
    }

    td {
      padding: 0;
      display: block;

      :first-child {
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
  }

  @media (min-width: 800px) {
    thead {
      display: table-header-group;
    }

    tbody {
      tr {
        display: table-row;
        padding: auto;
      }

      td {
        display: table-cell;
        margin: auto;
        padding: 15px;

        :first-child {
          border-right: 1px solid #d7d7d7;
          width: 200px;
        }
      }
    }
  }
`

export const pageQuery = graphql`
  query stihlProductTemplate($_id: String!, $sku: String!) {
    sanityStihlEquipment(_id: { eq: $_id }) {
      buyOnlineSlug
      category {
        handle
        title
      }
      description
      features {
        descriptionHtml
        title
      }
      images {
        asset {
          original: gatsbyImageData(width: 680)
          thumbnail: gatsbyImageData(layout: FIXED, width: 92)
        }
      }
      options {
        price
        title
      }
      similarEquipment {
        _id
        firstImage {
          asset {
            gatsbyImageData(width: 640)
          }
        }
        slug
        title
      }
      sku
      slug
      specifications {
        description
        title
      }
      subcategory {
        handle
        title
      }
      title
    }
    allHutsonPromotion(
      filter: { productList: { elemMatch: { product: { eq: $sku }, type: { eq: "stihl" } } } }
      sort: { fields: [endDate], order: DESC }
    ) {
      nodes {
        html
        link
        slug
        title
      }
    }
  }
`

export default StihlProductTemplate
